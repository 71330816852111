import {createWebHistory, createRouter } from "vue-router";
import {routerGuard} from './routerGuard'
import Public from '@/views/Public'

const routes = [
  {
    path: "/",
    component: Public,
  },
  {
    path: "/terms-of-service",
    name: "Terms of Service",
    component: () => import('../views/TermsOfService.vue'),
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: () => import('../views/PrivacyPolicy.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(routerGuard)

export default router;
