import { cloneDeep } from 'lodash';
import { sendSignInLinkToEmail, signInWithEmailLink, onAuthStateChanged, isSignInWithEmailLink } from "firebase/auth";
import {auth} from './firebase';

const actionCodeSettings = {
  "url": location.origin + '/app',
  "handleCodeInApp": true
};

export const getCurrentUser = () => new Promise((res, rej) => {
  const unsubscribe = onAuthStateChanged(auth, user => {
    unsubscribe();
    res(user);
  }, rej);
});

export const getIdToken = async () => auth.currentUser.getIdToken(true)

export const signOut = () => auth.signOut()

export const signin = ({email, path = '/confirm'}) => new Promise((res, rej) => {
  const settings = cloneDeep(actionCodeSettings)
  settings.url += path;
  sendSignInLinkToEmail(auth, email, settings).then(() => {
    window.localStorage.setItem('floux_emailForSignIn', email);
    res(res)
  }).catch(error => {
    rej(error)
  })
})

export const confirm = ({href, email = null}) => new Promise((res, rej) => {
  if (isSignInWithEmailLink(auth, href)) {
    email = email ? email : window.localStorage.getItem('floux_emailForSignIn');

    if (!email) {
      rej('no_email')
    }
    // The client SDK will parse the code from the link for you.
    signInWithEmailLink(auth, email, href).then((result) => {
      window.localStorage.removeItem('emailForSignIn');
      res(result)
    }).catch((error) => {
      rej({
        type: 'firebase',
        error,
      })
    });
  } else {
    rej()
  }
})

