<template>
  <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 50 50" :style="style" xml:space="preserve">
      <path fill="#000" d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">
        <animateTransform attributeType="xml"
          attributeName="transform"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="0.6s"
          repeatCount="indefinite"/>
        </path>
    </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '1rem'
    },
    height: {
      type: String,
      default: '1rem'
    }
  },
  computed: {
    style () {
      return {
        'enable-background':'new 0 0 50 50',
        width: this.width,
        height: this.height
      }
    }
  }
}
</script>

<style scoped>
svg path,
svg rect{
  fill: currentColor;
}
</style>