<template>
  <div class="public">
    <div ref="splash" class="public-splash fll--viewport origin">
      <div class="public-splash-main abs--top-center">
        <h1 class="public-splash-heading typ--i+2 typ--400 ofst--top-lg">
          Find your <em>flow</em>
        </h1>
        <h2 class="public-splash-subheading typ--iii ofst--top-lg typ--400">Intuitive mind-mapping software for Ux Designers.</h2>
        <div v-if="!success" class="public-splash-input flx typ--ii ofst--top-xl">
          <InputText v-model="email" class="public-input flx-fill">Enter email</InputText>
          <button
            type="submit"
            class="button--solid rst--button"
            :disabled="button_disabled"
            @click="handleSubmit"
          >
            <span v-if="!loading">Try Flo</span>
            <Loader v-else />
          </button>
        </div>
        <div v-else class="public-splash-message pad--all-md ofst--top-lg">
          {{success || error ? success : error}}
        </div>
        <p class="typ--center ofst--top-md">
          - or -
        </p>
        <form action="/app/demo" class="flx--default ofst--top-md">
          <button
            type="submit"
            class="button--solid rst--button flx-center"
            @click="handleSubmit"
          >
            <span v-if="!loading">Try the demo</span>
          </button>
        </form>
      </div>
      <div
        class="public-splash-explanation abs--bottom-center pad--all-lg origin pad--top-xl"
        :class="{showExplanation}"
      >
        <div class="public-splash-explanation-header abs--top-left abs--top-right pad--all-lg origin" @click="showExplanation = !showExplanation">
          <h3 class="typ--i+1">What is Floux?</h3>
          <button
            class="rst--button abs--top-right ofst--top-md ofst--right-md pad--top-sm"
          >
            <img :src="ChevronDown" alt="" :style="{'transform': showExplanation ? 'rotate(0)' : 'rotate(180deg)'}">
          </button>
        </div>

        <p class="ofst--top-md">
          Think 'Flow: UX.' This intuitive, simple app helps user experience designers organize their thoughts, their work, their user flows. Floux lets designers build simple mapping systems for the complicated, choose-your-own-adventure processes that unfold on the web and in all kinds of user-driven experiences. 
        </p>
        <h3 class="typ--iv ofst--top-lg">Who uses Floux? </h3>
            <p class="ofst--top-sm">
              Anyone with a lot on their mind. This versatile system can be used for all kinds of progressive mind mapping situations. Users might be solving dead ends on websites, perfecting interactive customer service experiences, planning real-world wayfinding systems, or just organizing thoughts. How you Flo(ux) is up to you.
            </p>
      </div>
    </div>
    <section v-if="false" ref="designers" class="designers pad--all-lg origin ovr--hidden"> 
        <!-- <img src="@/assets/public/joanna-kosinska-1_CMoFsPfso-unsplash.jpg" alt="" class="abs--top-left min--fill"> -->
      <div class="flx-wrap ofst--top-md origin fll--parent">
        <div class="@base--1-2 @phone--1-1">
          <!-- <img src="@/assets/public/flow_01.png" class="fll--parent-w" alt=""> -->
        </div>
        <div class="public-designers @base--1-2 @phone--1-1 origin">
          <div class="public-designers-block abs--center-center fll--parent-w pad--all-lg">
            <h3 class="typ--i+1 pad--top-md">Who uses Floux? </h3>
            <p class="ofst--top-sm">
              Anyone with a lot on their mind. This versatile system can be used for all kinds of progressive mind mapping situations. Users might be solving dead ends on websites, perfecting interactive customer service experiences, planning real-world wayfinding systems, or just organizing thoughts. How you Flo(ux) is up to you.
            </p>
          </div>
        </div>
      </div>
      
    </section>

    <section v-if="false" ref="developers" class="designers2 pad--all-lg"> 
      <div class="public-developers flx-wrap gtr--xl ofst--top-md">
        <div class="@base--1-2 @phone--1-1">
          <h3 class="typ--i+1 pad--top-md">Find your flow</h3>
          <p class="ofst--top-sm">
          </p>
        </div>
        <div class="@base--1-2 @phone--1-1">
          <!-- <img src="https://placeponi.es/400/300" class="fll--parent-w" alt=""> -->
        </div>
        
      </div>
    </section>
    
    <div v-if="false" class="signup2 origin">
      
      <div v-if="!success" class="public-splash-input ofst--top-lg">
        <h3 class="inverse typ--i+1">Give FloUx a Try</h3>
        <div class="flx typ--ii ofst--top-md">
          <InputText v-model="email" class="public-input flx-fill">Enter email</InputText>
          <button
            type="submit"
            class="button--solid rst--button"
            :disabled="button_disabled"
            @click="handleSubmit"
          >
            <span v-if="!loading">Try Flo</span>
            <Loader v-else />
          </button>
        </div>
      </div>
      <div v-else class="public-splash-message pad--all-md ofst--top-lg">
        {{success || error ? success : error}}
      </div>
    </div>
    <footer v-if="false" class="footer pad--all-xl">
      <div class="flx">
        <div class="flx-center">
          <ul class="rst--list flx gtr--md">
            <!-- <li><button type="link" class="footer-link rst--button" @click.stop.prevent="scrollTo('splash')">Back to top</button></li> -->
            <li><router-link class="footer-link rst--link" :to="{name: 'Privacy Policy'}" target="_blank">Privacy Policy</router-link></li>
            <!-- <li><router-link class="footer-link rst--link" :to="{name: 'Terms of Service'}" target="_blank">Terms of Service</router-link></li> -->
          </ul>
        </div>
      </div>
    </footer>

    <div class="menu fxd--top-left fxd--top-right pad--all-sm">
      <div class="flx">
        <ul class="rst--list flx gtr--md">
          <li class="ofst--right-md ofst--left-md">
            <button @click="scrollTo('splash')" class="menu-button rst--button">
              <img class="menu-logo" src="@/assets/logo.png" alt="">
            </button>
          </li>
          <!-- <li><button class="menu-button rst--button" @click="scrollTo('designers')">For Designers</button></li> -->
          <!-- <li><button class="menu-button rst--button" @click="scrollTo('developers')">For Developers</button></li> -->
        </ul>
        <div class="flx-end ofst--right-md">
          <form action="/app">
            <button class="menu-button rst--button">Sign in</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/parts/input-text";
import {minimumTimeout} from '@/lib/minimumTimeout'
import Loader from '@/components/parts/loader.vue'
import {isValidEmail} from '../lib/validateEmail'
import ChevronDown from '../assets/icons/chevron-down.svg';

export default {
  setup () {
    return {
      ChevronDown
    }
  },
  data: () => ({
    email: '',
    loading: false,
    success: null,
    error: null,
    showExplanation: false
  }),
  components: {InputText, Loader },
  computed: {
    button_disabled() {
          return isValidEmail(this.email);
      }
  },
  methods: {
    scrollTo (ref) {
      this.$refs[ref].scrollIntoView({
        behavior: 'smooth'
      });
    },
    handleSubmit () {
      this.loading = true;
      const timeout = minimumTimeout();
      this.$store.dispatch('auth/signin', {email: this.email}).then(() => {
        timeout().then(() => {
          this.loading = false;
          this.success = `Please check '${this.email}' for a confirmation link`;
          setTimeout(() => {
            this.success = null;
          }, 7000)
        })
      }).catch(() => {
        this.loading = false;
        this.error = `Sorry, there was an error, please try again.`
        setTimeout(() => {
            this.error = null;
          }, 7000)
      })
    }
  }
};
</script>

<style lang="scss" scoped>
$brand: #28B67D;

.min--fill {
  min-width: 100%;
  min-height: 100%;
  max-width: 100%;
}

.designers {
  background-image: url('../assets/public/joanna-kosinska-1_CMoFsPfso-unsplash.jpg');
  background-size: cover;
}

.designers2 {
  background-image: url('https://images.unsplash.com/photo-1458432449677-469b01f8ed08?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2378&q=80');
  background-size: cover;
  color: #fff;
}

.signup2 {
  padding: 20vh 0;
  background: #111;
  background-image: url('../assets/public/noita-digital-MdmcpX2ofRk-unsplash.png');
  background-size: cover;
}

.public {

  &-designers {
    height: calc(100vh - 3rem);
    min-height: 800px;
    

    &-block {
      background-color: rgba(0, 0, 0, 0.8);
      color: #fff;
    }
  }

  &-developers {
    min-height: calc(100vh - 3rem);
  }

  p {
    line-height: 1.8em;
  }

  &-input {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;

    ::v-deep input {
      border: solid thin #ddd;
      border-top-left-radius: .25rem;
      border-bottom-left-radius: .25rem;
    }
  }

  &-splash {
    height: 100vh;
    background-image: url("../assets/public/20210929155446024_SparkVideo.gif");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
    // background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(245,245,245,1) 50%, rgba(190,190,190,1) 100%);
    z-index: 1;

    &-explanation {
      color: #ffffff;
      transform: translate(-50%, 100%) translate(0, -5rem);
      background-color: rgba(0, 0, 0, 0.9);
      width: calc(100% - 4rem);
      max-width: 36rem;
      transition: transform .4s ease-in-out;

      &.showExplanation {
        transform: translate(-50%, 100%) translate(0, -100%);
      }

      &-header {
        color: rgba(white, 0.8);
        cursor: default;
        transition: color .15s ease;

        &:hover {
          color: rgba(white, 1);
        }
      }
    }

    &-heading {
      color: #333;
      text-shadow: 0 0 40px white, 0 0 15px white, 0 0 10px white, 0 0 5px white, 0 0 0 white, 0 0 20px white, 0 0 15px white, 0 0 10px white, 0 0 5px white, 0 0 0 white, 0 0 20px white, 0 0 15px white, 0 0 10px white, 0 0 5px white, 0 0 0 white;
      // font-weight: 100;
      // text-transform: uppercase;
      // letter-spacing: .25em;

      > em {
        color: #28B67D;
        font-style: normal;
      }
    }


    &-subheading {
      font-weight: 100;
      text-transform: uppercase;
      letter-spacing: .25em;
      line-height: 1.2em;
      max-width: 28em;
      margin-left: auto;
      margin-right: auto;
      text-shadow: 0 0 2px rgba(#eee,1);
    }

    &-input {
      max-width: 34rem;
      margin-left: auto;
      margin-right: auto;
    }

    &-main {
      width: calc(100% - 4rem);
      max-width: 42rem;
      text-align: center;
      top: 10vh;
    }

    &-message {
      background-color: white;
    }
  }
}

.menu {
  z-index: 10;
  // border-bottom: solid 1px #333;

  &-button {
    // border-bottom: solid .5rem #CF9301;
    height: 3rem;

    &:hover {
      text-shadow: 0 0 1px rgba(black, 0.4);
    }
  }
  
  &-logo {
    width: 6rem;
  }
}

.footer {
  &-link {
    color: #008080;

    &:hover {
      text-shadow: 0 0 1px rgba(#008080, 0.4);
      cursor: default;
    }
  }
}

.sft--return {
  white-space: nowrap;
}

.inverse {
  color: #ffffff;
}
</style>