import 'thin.css'
import '@/assets/main.scss'
import { createApp, h, resolveComponent } from 'vue';
import router from './router/public';
import store from './store/public'
import VueGtag from "vue-gtag-next";

console.log('App copyright AJM')

const app = createApp({render: () => h(resolveComponent('router-view'))});
app.use(VueGtag, {
  property: {
    id: "G-R17LEWKQDZ"
  }
});
app.use(router);
app.use(store);
app.mount('#app');