export function minimumTimeout (duration = 650) {
  let loading = true;
  let cb;
  setTimeout(() => {
    if (!loading) {
      if (cb) {
        cb(loading)
      }
    } else {
      loading = false
    }
  }, duration)
  return () => new Promise((res) => {
    cb = res; 
    if (loading) {
      loading = false;
      return
    }
    res(loading)
  })
}