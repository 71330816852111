import auth from '../store/auth';
import { createStore } from 'vuex'

const store = createStore({
  modules: {auth},
  state: {},
  getters: {},
  mutations: {},
  actions: {}});

  export default store;