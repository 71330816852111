import {signin, confirm, getCurrentUser, signOut} from '../database/auth';

export default {
  namespaced: true,
  state: {
    uid: null,
  },
  getters: {},
  mutations: {
    setUser (state, uid) {
      state.uid = uid
    }
  },
  actions: {
    signin: (_, email) => signin(email),
    confirm: (_, {href, email}) => confirm({href, email}),
    checkSignedIn: ({commit}) => new Promise((res, rej) => {
      getCurrentUser().then((response) => {
        if (response && response.uid) {
          commit('setUser', response.uid)
          res()
        } else {
          rej()
        }
      })
    }),
    signOut: () => signOut(),
  }
}