import {getCurrentUser} from '../database/auth';

export function routerGuard (to, _, next) {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  getCurrentUser().then((user) => {
    if (requiresAuth && !user) {
      next('signin');
    } else {
      next();
    }      
  });
}